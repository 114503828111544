/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_include_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
import mixpanel from "./helpers/mixpanel";
import "./controllers";
//= require_tree ./helpers
import "trix";
import "@rails/actiontext";

function trackClick(selector, eventName) {
  const elements = document.querySelectorAll(selector);
  elements.forEach((element) => {
    element.addEventListener("click", () => {
      mixpanel.track(eventName);
    });
  });
}

// Track specific selectors
document.addEventListener("DOMContentLoaded", () => {
  // Track hogaru landing page quote button
  // stimulus file app/javascript/controllers/public_pages/home/show_services_controller.js and app/views/public_pages/home/_new_landing_hogaru.html.slim view

  // Track Services cards
  trackClick(".track-card-limpia", "track-select-limpia-quote");
  trackClick(".track-card-aporta", "track-select-aporta-quote");

  // Track Hogarú landing cards
  trackClick(".track-home-card-limpia", "track-card-limpia-quote");
  trackClick(".track-home-card-aporta", "track-card-aporta-quote");

  // Track cleaning landing page quote button
  // Stimulus file app/javascript/controllers/public_pages/cleaning/landing_cleaning_controller.js and app/views/public_pages/cleaning/_new_landing_cleaning.html.slim view

  //track boton registrarse ambos a/b test
  trackClick(
    ".track-back-button-register",
    "track-back-button-register-limpia"
  );
  trackClick(
    ".track-back-button-companies-register",
    "track-back-button-companies-register"
  );
  // Track welcome client cards
  trackClick("#track-welcome-card-limpia", "track-welcome-card-limpia");
  trackClick("#track-welcome-card-aporta", "track-welcome-card-aporta");
  trackClick("#track-welcome-card-encuentra", "track-welcome-card-encuentra");

  //track quote version
  trackClick(".track-occasional", "track-occasional-option-frecuency");
  trackClick(".track-once-a-week", "track-once-a-week-option-frecuency");
  trackClick(".track-biweekly", "track-biwwekly-option-frecuency");
  trackClick(
    ".track-occasional-companies",
    "track-companies-occasional-option-frecuency"
  );
  trackClick(
    ".track-once-a-week-companies",
    "track-companies-once-a-week-option-frecuency"
  );
  trackClick(
    ".track-biweekly-companies",
    "track-companies-biweekly-option-frecuency"
  );
  //trackClick(".track-button-frecuency", "track-continue-button-frecuency"); Stimulus
  trackClick(".track-back-button-frecuency", "track-back-button-frecuency");
  trackClick(
    ".track-back-button-companies-frecuency",
    "track-back-button-companies-frecuency"
  );
  //track shifts quote single version
  trackClick(".track-m-single", "track-single-morning-option-shift");
  trackClick(".track-t-single", "track-single-afternoon-option-shift");
  trackClick(".track-c-single", "track-single-complete-option-shift");
  trackClick(
    ".track-continue-single-shift",
    "track-continue-button-shift-single"
  );
  trackClick(".track-back-single-shift", "track-back-button-shift-single");
  trackClick(
    ".track-m-companies-single",
    "track-single-morning-companies-option-shift"
  );
  trackClick(
    ".track-t-companies-single",
    "track-single-afternoon-companies-option-shift"
  );
  trackClick(
    ".track-c-companies-single",
    "track-single-complete-companies-option-shift"
  );
  trackClick(
    ".track-back-companies-single-shift",
    "track-back-companies-single-shift"
  );
  //trackClick(".track-continue-button-calendar-single", "track-continue-button-calendar-single"); stimulus
  trackClick(
    ".track-back-button-calendar-single",
    "track-back-button-calendar-single"
  );
  trackClick(
    ".track-back-button-companies-calendar-single",
    "track-back-button-companies-calendar-single"
  );
  trackClick(
    ".track-button-continue-summary-single",
    "track-button-continue-summary-single"
  );
  trackClick(
    ".track-button-companies-continue-summary-single",
    "track-button-companies-continue-summary-single"
  );
  trackClick(
    ".track-button-back-summary-single",
    "track-button-back-summary-single"
  );
  trackClick(
    ".track-button-companies-back-summary-single",
    "track-button-companies-back-summary-single"
  );
  //track recurrency quote version
  trackClick(".track-discout-button", "track-discount-button");
  trackClick(
    ".track-companies-discout-button",
    "track-companies-discount-button"
  );
  trackClick(".track-m", "track-morning-option-shift");
  trackClick(".track-t", "track-afternoon-option-shift");
  trackClick(".track-c", "track-complete-option-shift");
  trackClick(".track-companies-m", "track-morning-companies-option-shift");
  trackClick(".track-companies-t", "track-afternoon-companies-option-shift");
  trackClick(".track-companies-c", "track-complete-companies-option-shift");
  //trackClick(".track-button-continue-shifts", "track-continue-button-shift"); Stimulus
  trackClick(".track-button-back-shifts", "track-back-button-shift");
  trackClick(
    ".track-companies-button-back-shifts",
    "track-companies-button-back-shifts"
  );
  trackClick(".quote-box-day-1", "track-monday-option-schedule");
  trackClick(".quote-box-day-2", "track-tuesday-option-schedule");
  trackClick(".quote-box-day-3", "track-wednesday-option-schedule");
  trackClick(".quote-box-day-4", "track-thursday-option-schedule");
  trackClick(".quote-box-day-5", "track-friday-option-schedule");
  trackClick(".quote-box-day-6", "track-saturday-option-schedule");

  trackClick(
    ".companies-quote-box-day-1",
    "track-monday-companies-option-schedule"
  );
  trackClick(
    ".companies-quote-box-day-2",
    "track-tuesday-companies-option-schedule"
  );
  trackClick(
    ".companies-quote-box-day-3",
    "track-wednesday-companies-option-schedule"
  );
  trackClick(
    ".companies-quote-box-day-4",
    "track-thursday-companies-option-schedule"
  );
  trackClick(
    ".companies-quote-box-day-5",
    "track-friday-companies-option-schedule"
  );
  trackClick(
    ".companies-quote-box-day-6",
    "track-saturday-companies-option-schedule"
  );
  //trackClick(".track-continue-button-schedule", "track-continue-button-schedule"); stimulus
  trackClick(".track-back-button-schedule", "track-back-button-schedule");
  trackClick(
    ".track-back-companies-button-schedule",
    "track-back-companies-button-schedule"
  );
  trackClick(".track-change-plan", "track-button-change-plan");
  trackClick(".track-not-interested", "track-button-not-interested");
  trackClick(
    ".track-companies-change-plan",
    "track-companies-button-change-plan"
  );
  trackClick(
    ".track-companies-not-interested",
    "track-companies-button-not-interested"
  );
  trackClick(
    ".track-back-recurrency-schedule",
    "track-back-recurrency-schedule"
  );
  trackClick(
    ".track-back-companies-recurrency-schedule",
    "track-back-companies-recurrency-schedule"
  );
  trackClick(
    ".track-continue-recurrency-schedule",
    "track-recurrency-schedule"
  );
  trackClick(
    ".track-companies-continue-recurrency-schedule",
    "track-companies-continue-recurrency-schedule"
  );
  //track sign up version
  trackClick(
    ".track-client-occasional",
    "track-occasional-option-frecuency-signup"
  );
  trackClick(
    ".track-client-once-a-week",
    "track-once-a-week-option-frecuency-signup"
  );
  trackClick(
    ".track-client-biweekly",
    "track-biweekly-option-frecuency-signup"
  );
  //trackClick(".track-button-client-frecuency", "track-continue-button-frecuency-signup"); Stimulus
  trackClick(
    ".track-back-button-client-frecuency",
    "track-back-button-frecuency-signup"
  );
  //track single service client version
  trackClick(
    ".track-m-client-single",
    "track-single-client-morning-option-shift-signup"
  );
  trackClick(
    ".track-t-client-single",
    "track-single-client-afternoon-option-shift-signup"
  );
  trackClick(
    ".track-c-client-single",
    "track-single-client-complete-option-shift-signup"
  );
  //trackClick(".track-continue-single-client-shift", "track-continue-button-shift-single-signup"); Stimulus
  trackClick(
    ".track-back-single-client-shift",
    "track-back-button-shift-single-signup"
  );
  trackClick(
    ".track-continue-button-calendar-single-signup",
    "track-continue-button-calendar-single-signup"
  );
  trackClick(
    ".track-back-button-calendar-single-signup",
    "track-back-button-calendar-single-signup"
  );
  trackClick(
    ".track-button-continue-summary-single-client",
    "track-button-continue-summary-single-signup"
  );
  trackClick(
    ".track-button-back-summary-single-client",
    "track-button-back-summary-single-signup"
  );
  //track recurrency client version
  trackClick(".track-m-client", "track-morning-option-shift-signup");
  trackClick(".track-t-client", "track-afternoon-option-shift-signup");
  trackClick(".track-c-client", "track-complete-option-shift-signup");
  //trackClick(".track-button-client-shifts","track-continue-button-shift-signup"); Stimulus
  trackClick(
    ".track-back-button-client-shifts",
    "track-back-button-shift-signup"
  );
  trackClick(".client-box-day-1", "track-monday-option-schedule-signup");
  trackClick(".client-box-day-2", "track-tuesday-option-schedule-signup");
  trackClick(".client-box-day-3", "track-wednesday-option-schedule-signup");
  trackClick(".client-box-day-4", "track-thursday-option-schedule-signup");
  trackClick(".client-box-day-5", "track-friday-option-schedule-signup");
  trackClick(".client-box-day-6", "track-saturday-option-schedule-signup");
  //trackClick(".track-button-client-schedule","track-continue-recurrency-schedule-signup"); Stimulus
  trackClick(
    ".track-back-client-schedule",
    "track-back-recurrency-schedule-signup"
  );
  trackClick(
    ".track-button-back-schedule-client",
    "track-button-back-schedule-client"
  );
  trackClick(
    ".track-button-continue-summary-client",
    "track-button-continue-summary-client"
  ); // Stimulus
  //track next billing
  trackClick(".track-next-billing", "track-next-billing");

  //Track Elisa landing page
  trackClick(".track-start-affiliation-top", "track-start-affiliation-top");
  trackClick(
    ".track-start-affiliation-bottom",
    "track-start-affiliation-bottom"
  );
  trackClick(".track-free-consulting-top", "track-free-consulting-top");
  trackClick(".track-free-consulting-bottom", "track-free-consulting-bottom");
});
